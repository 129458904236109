* {
  font-family: "Raleway", sans-serif;
  background-color: #f4f5f7;
  /* background-color: white; */
  /* color: #0f4372; */
}

.navbar-toggler-icon::before {
  size: 20px;
  font-family: "Font Awesome\ 5 Free"; /* Font family for the Font Awesome icons */
  font-weight: bold; /* Font weight for the Font Awesome icons */
  
}

.navbar-toggler-icon{
  background-color: transparent;
  border: none;
}

.navbar-toggler{
  background-color: transparent;
  border: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0f4372 !important;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-icon-sidebar {
  margin-right: 1rem;
  background-color: transparent !important;
}

.nav-item-sidebar-active {
  border-right: 4px solid #0f4372;
}

.nav-item-sidebar,
.nav-item {
  border: none;
  background-color: #f8f9fa !important;
}

.nav-item > .nav-link {
  color: #0f4372 !important;
}

.nav-item > .nav-link.active {
  /* color: #0f4372 !important;
  border-right: 4px solid #0f4372 !important; */
  color: white !important;
  background-color: #0f4372 !important;
}

.nav-item > .nav-link.active:hover {
  /* color: #0f4372 !important;
  border-right: 4px solid #0f4372 !important; */
  color: white !important;
  background-color: #0f4372 !important;
}

.nav-item > .nav-link:hover {
  color: white !important;
  /* border-right: 4px solid #0f4372 !important; */
  background-color: #0f4372 !important;
}

.sidebar-menu-active {
  color: #0f4372 !important;
  border-right: 4px solid #0f4372 !important;
}

.sidebar-menu {
  color: #86a0b89d !important;
}

.notification-bell-icon {
  color: #86a0b89d !important;
  font-size: 20px !important;
  background-color: transparent;
}

.colibri-logo-image {
  width: 70% !important;
  background-color: transparent;
}

.colibri-logo {
  width: 40% !important;
  background-color: transparent;
}

.bu-logo-image {
  width: 50% !important;
  background-color: transparent;
}

.full-width-navbar {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

#basic-nav-dropdown::after {
  display: none !important;
}

#notications-dropdown::after {
  display: none !important;
}

.notification-drop-list {
  border: none !important;
  border-radius: 0 !important;
  border-top: 3px solid #0f4372 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin-top: 11px !important;
  width: 20rem;
}

.profile-drop-list {
  border: none !important;
  border-radius: 0 !important;
  border-top: 3px solid #0f4372 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin-top: 11px !important;
  min-width: 13rem !important;
}

/* circle-image-dropdown.css */

#circle-image-dropdown::after {
  display: none !important;
}

.circle-image-dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none; /* Remove underline */
}

@media (min-width:768px) {
  #badge-category{
    background-color: #0f4372 !important;
    font-size: 13px !important;
  }
  
  #badge-lh{
    background-color: #0f4372 !important;
    font-size: 13px !important;
  }
}

@media (max-width: 575.98px) {
  .circle-image-dropdown-toggle::after {
    display: none;
  }

  .bu-logo {
    display: none;
  }
}

.circle-image-dropdown-toggle::after {
  font-family: FontAwesome; /* Add the font family for the arrow icon */
  content: \f078; /* The Unicode character for the down arrow */
  margin-left: 10px; /* Add margin to separate the arrow from text */
  color: #0f4372 !important;
}

.circle-image-dropdown-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.circle-image-dropdown-title {
  margin: 0;
  font-size: 16px;
  color: #0f4372 !important;
  text-decoration: none; /* Remove underline */
}

.circle-image-dropdown-subtitle {
  margin: 0;
  font-size: 14px;
  color: #0f4372 !important;
  text-decoration: none; /* Remove underline */
}

.badge {
  font-weight: 100;
  position: relative;
  display: inline-block;
}

.badge-icon {
  color: #86a0b89d;
  font-size: 24px;
}

.badge-number {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #0f4372;
  color: white;
  border-radius: 50%;
  padding: 4px !important;
  font-size: 15px;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.custom-dropdown-item {
  padding: 8px;
  transition: background-color 0.3s;
  text-decoration: none !important;
}

.custom-dropdown-item * {
  background-color: white !important;
}

.custom-dropdown-item:hover {
  /* background-color: #f8f9fa; */
}

.custom-dropdown-title {
  /* font-weight: bold; */
  font-size: 16px;
}

.custom-dropdown-subtitle {
  color: #999;
  font-size: 13px;
}

.badge-primary {
  background-color: #6c757d;
}

.ellipsis-button {
  background-color: none !important;
  border-color: #0f4372 !important;
  border-radius: 50px !important;
  width: 150px !important;
  border: none !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  color: #999 !important;
  cursor: pointer;
}

.ellipsis-button:hover {
  background-color: none !important;
  border-color: #0f4372 !important;
  border-radius: 50px !important;
  border: 1px solid !important;
  width: 150px !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  color: #0f4372 !important;
}

.ellipsis-button.active {
  background-color: #0f4372 !important;
  border-color: #0f4372 !important;
  width: 150px !important;
  border-radius: 50px !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  color: white !important;
}

.ellipsis-button-course-nav {
  background-color: none !important;
  border-radius: 50px !important;
  min-width: 50px !important;
  border: 1px solid #f4f5f7 !important;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  color: #999 !important;
  cursor: pointer;
}

.ellipsis-button-course-nav:hover {
  background-color: none !important;
  border-color: #0f4372 !important;
  border-radius: 50px !important;
  border: 1px solid !important;
  margin-right: 10px;
  color: #0f4372 !important;
}

.ellipsis-button-course-nav.active {
  background-color: #0f4372 !important;
  border-color: #0f4372 !important;
  border-radius: 50px !important;
  margin-right: 10px;
  color: white !important;
}

.card {
  border: none !important;
  background-color: transparent !important;
}

.button-apply-now {
  border: none !important;
  background: rgb(243, 156, 78);
  background: linear-gradient(
    90deg,
    rgba(243, 156, 78, 1) 0%,
    rgba(243, 116, 4, 1) 55%
  );
}

.card-apply-now {
  position: relative;
  min-height: 200px !important;
  background-color: #f2aa6a;
  border: 1px solid;
  border-radius: 15px;
  border-color: #f2aa6a;
}

.card-apply-now > .card-body {
  background-color: #f2aa6a;
  border: 1px solid;
  border-radius: 15px 15px 0 0;
  border-color: #f2aa6a;
}

.card-apply-now > .card-body > .text-wrapper {
  position: absolute;
  z-index: 1;
  background-color: transparent !important;
}

@media (max-width: 575.98px) {
  .card-apply-now > .card-body > .text-wrapper {
    position: absolute;
    z-index: 1;
    background-color: #f2aa6a;
    font-size: 15px !important;
  }

  .image-container {
    /* border: 1px solid red; */
    position: absolute;
    top: 70px !important;
    right: 0px !important;
    background-color: transparent;
  }

  .custom-image {
    width: 60.7% !important;
    height: 65% !important;
    object-fit: cover;
    border-radius: 15px;
  }

  .card-apply-now {
    position: relative;
    height: 150px !important;
    background-color: #f2aa6a;
    border: 1px solid;
    border-radius: 15px;
    border-color: #f2aa6a;
  }

  .ellipsis-button {
    background-color: none !important;
    border-color: #0f4372 !important;
    border-radius: 50px !important;
    width: 100px !important;
    border: none !important;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    color: #999 !important;
    cursor: pointer;
  }

  .ellipsis-button:hover {
    background-color: none !important;
    border-color: #0f4372 !important;
    border-radius: 50px !important;
    border: 1px solid !important;
    width: 100px !important;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    color: #0f4372 !important;
  }

  .ellipsis-button.active {
    background-color: #0f4372 !important;
    border-color: #0f4372 !important;
    width: 100px !important;
    border-radius: 50px !important;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
    color: white !important;
  }
}

.card-apply-now > .card-footer {
  background-color: #f2aa6a;
  border: 1px solid;
  border-radius: 0 0 15px 15px !important;
  border-color: #f2aa6a;
}

.image-container {
  /* border: 1px solid red; */
  position: absolute;
  right: 0px;
  background-color: transparent;
}

.custom-image {
  width: 85%;
  height: 85%;
  object-fit: cover;
  border-radius: 15px;
}

.card-event {
  height: 200px !important;
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.view-all {
  color: #0f4372;
  text-decoration: none;
  font-weight: lighter;
  font-size: 12px;
  background-color: white;
}

.view-all:hover {
  text-decoration: none;
}

.card-event > .card-title {
  background-color: white;
}

.card-event-content {
  min-height: 80% !important;
  background-color: #ddfdea;
  border: 1px solid;
  border-radius: 15px;
  border-color: #ddfdea;
  color: #0f4372 !important;
}

.custom-list-group {
  border: none;
  background: transparent;
}

.custom-list-group-item {
  border: none !important;
  background: transparent !important;
}

.custom-list-item {
  color: #0f4372 !important;
  font-size: 13px;
  background: none !important;
}

.custom-list-item > span {
  background: none !important;
}

.card-course-progress {
  height: 200px !important;
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.view-all-course-progress {
  color: #0f4372;
  text-decoration: none;
  font-weight: lighter;
  font-size: 12px;
  background-color: white;
}

.view-all-course-progress:hover {
  text-decoration: none;
}

.card-course-progress > .card-title {
  background-color: white;
}

.card-course-progress-content {
  min-height: 80% !important;
  background-color: #f8e5ff;
  border: 1px solid;
  border-radius: 15px;
  border-color: #f8e5ff;
  color: #0f4372 !important;
}

.card-overview {
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.card-overview > .card-title {
  background-color: white;
}

.view-all-activity {
  color: #0f4372;
  text-decoration: none;
  font-weight: lighter;
  font-size: 15px;
  background-color: white;
}

.view-all-activity:hover {
  text-decoration: none;
}

.card-level-status {
  background-color: #fffcdb;
  border: 1px solid;
  border-radius: 25px;
  border-color: #fffcdb;
  color: #0f4372 !important;
}

.card-level-status-icon {
  background: #fff699;
  border: 1px solid;
  border-radius: 50%;
  border-color: #fff699;
  padding: 5px;
  color: #f2c94c;
  font-size: 20px;
}

.card-level-status > .card-title {
  background-color: #fffcdb;
}

/* .gold-star {
  background: transparent;
  color: #f2c94c;
  font-size: 30px;
} */

.gold-star {
  background: transparent;
  font-size: 30px;
  color: gold;
}

.silver-star {
  background: transparent;
  font-size: 30px;
  color: silver;
}

.bronze-star {
  background: transparent;
  font-size: 30px;
  color: #cd7f32; /* Use a bronze color of your choice */
}

.no-star {
  background: transparent;
  font-size: 30px;
  color: #fffcdb; /* Use a bronze color of your choice */
}


.card-class-points > .card-body {
  background-color: #fff699 !important;
  border: 1px solid;
  border-radius: 15px;
  border-color: #fff699;
  color: #0f4372 !important;
}

.card-class-points .card-title {
  font-size: 15px;
  color: #0f4372 !important;
  background: transparent !important;
}

.card-class-points > .card-body {
  background-color: #fff699 !important;
  border: 1px solid;
  border-radius: 15px;
  border-color: #fff699;
  color: #0f4372 !important;
}

.card-class-points .card-title {
  font-size: 15px;
  color: #0f4372 !important;
  background: transparent !important;
}

.card-class-ranks > .card-body {
  background-color: #fff699 !important;
  border: 1px solid;
  border-radius: 15px;
  border-color: #fff699;
  color: #0f4372 !important;
}

.card-class-ranks .card-title {
  font-size: 15px;
  color: #0f4372 !important;
  background: transparent !important;
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-bottom: 0;
  padding-left: 10px !important;
}

.card-learning-hours {
  background-color: #fee7da;
  border: 1px solid;
  border-radius: 25px;
  border-color: #fee7da;
  color: #0f4372 !important;
}

.card-learning-hours-icon {
  background: #fecdb2;
  border: 1px solid;
  border-radius: 50%;
  border-color: #fecdb2;
  padding: 5px;
  color: #fe8542;
  font-size: 20px;
}

.card-learning-hours > .card-title {
  background-color: #fee7da;
}

.custom-card-title {
  display: flex;
  align-items: flex-end;
}

.larger-text {
  font-size: 30px;
  font-weight: 600;
  margin-right: 5px;
}

.smaller-text {
  font-size: 20px;
  font-weight: bold;
  padding: 0;
}

.card-course-learned {
  background-color: #daedfd;
  border: 1px solid;
  border-radius: 25px;
  border-color: #daedfd;
  color: #0f4372 !important;
}

.card-course-learned-icon {
  background: #b1dafd;
  border: 1px solid;
  border-radius: 50%;
  border-color: #b1dafd;
  padding: 5px;
  color: #0f4372;
  font-size: 20px;
}

.card-course-learned > .card-title {
  background-color: #daedfd;
}

.progressbar-complete-color-1 {
  height: 5px !important;
  background-color: #93d6af !important;
}

.progressbar-complete-color-2 {
  background-color: #27ae60 !important;
}

.progressbar-ongoing-color-1 {
  height: 5px !important;
  background-color: #f8cca4 !important;
}

.progressbar-ongoing-color-2 {
  background-color: #f2994a !important;
}

.progressbar-unstarted-color-1 {
  height: 5px !important;
  background-color: #f5abab !important;
}

.progressbar-unstarted-color-2 {
  background-color: #f5abab !important;
}


.progressbar-complete-label {
  margin-bottom: 0px;
  padding: 0 !important;
  color: #27ae60 !important;
}

.progressbar-ongoing-label {
  margin-bottom: 0px;
  padding: 0 !important;
  color: #f2994a !important;
}

.progressbar-ongoing-label {
  margin-bottom: 0px;
  padding: 0 !important;
  color: #f5abab !important;
}

.card-course .card-title {
  color: #0f4372;
  font-size: 16px;
}

.card-course .card-title {
  color: #0f4372;
  font-size: 16px;
}

.card-course {
  background-color: white;
  border: 1px solid;
  border-radius: 0 0 15px 15px;
  border-color: #e1e1e3;
  color: #0f4372 !important;
  max-height: 330px;
}

.card-course {
  background-color: white;
  border: 1px solid;
  border-radius: 0 0 15px 15px;
  border-color: #e1e1e3;
  color: #0f4372 !important;
}

.card-course-image {
  border: 1px solid !important;
  border-radius: 15px 15px 0 0 !important;
  border-color: #e1e1e3 !important;
}

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* background-color: white; */
}

.error-content {
  text-align: center;
}

.error-title {
  font-size: 36px;
  font-weight: bold;
}

.error-subtitle {
  font-size: 18px;
}

.muted-text {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #888;
}

.top-left-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 170px;
  height: 170px;
}

.bottom-right-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 170px;
  height: 170px;
}

.top-left-image-login {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
}

.bottom-right-image-login {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 120px;
}

.btn-primary {
  background-color: #0f4372 !important;
  border-color: #0f4372 !important;
}

.logo-login {
  width: 35% !important;
  /* width: 50px;
  height: 50px; */
}

.gap-10 > * + * {
  margin-left: 10px;
}

.truncated-text-container {
  width: 150px; /* Adjust the width as needed */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncated-text {
  display: inline-block;
  vertical-align: middle;
}

.truncate-badge {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.slider_container {
  margin: 2%;
}

.slick-slide {
  background-color: red !important;
  box-sizing: border-box;
}

.slick-dots {
  background-color: transparent !important;
}

.slick-list {
  background-color: white;
}

div[tabindex="-1"][style="width: 100%; display: inline-block;"] {
  background-color: white;
}

.slick-prev::before {
  color: #0f4372 !important;
}

.slick-next::before {
  color: #0f4372 !important;
}

.slick-dots > li {
  background-color: white;
}
/* .card-learning-hours>.card-body{
  background-color: #fecdb2 !important;
  border: 1px solid;
  border-radius: 15px;
  border-color: #fecdb2;
  color: #0f4372 !important;
} */

/* 
.card-class-points>.card-body{
  background-color: #fff699 !important;
  border: 1px solid;
  border-radius: 15px;
  border-color: #fff699;
  color: #0f4372 !important;
}

.card-class-points .card-title{
  font-size: 15px;
  color: #0f4372 !important;
  background: transparent !important;
}

.card-class-ranks>.card-body{
  background-color: #fff699 !important;
  border: 1px solid;
  border-radius: 15px;
  border-color: #fff699;
  color: #0f4372 !important;
}

.card-class-ranks .card-title{
  font-size: 15px;
  color: #0f4372 !important;
  background: transparent !important;
}

.vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-bottom: 0;
    padding-left: 10px !important;
} */

.custom-dropdown-container {
  background-color: white !important;
  border-radius: 30px;
  display: inline-block;
  /* padding: 0 10px; */
}

.custom-dropdown-button::after {
  background-color: transparent !important;
  color: red !important;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
}

.custom-dropdown-button {
  background-color: transparent !important;
  color: #999 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 50px;
  width: 100%;
}

.card-program {
  background-color: white;
  border: 1px solid;
  border-radius: 15px 15px 15px 15px;
  border-color: #e1e1e3;
  color: #0f4372 !important;
  max-height: 330px;
}

.card-program .card-title {
  color: #0f4372;
  font-size: 16px;
  text-align: center;
}

.card-program * {
  background-color: #daedfd;
}

.card-cert-dashboard {
  background-color: #fffcdb;
  border: 1px solid;
  border-radius: 25px;
  border-color: #fffcdb;
  color: #0f4372 !important;
}

.card-cert-dash-last > .card-body {
  background-color: #fff699 !important;
  border: 1px solid;
  border-radius: 15px;
  border-color: #fff699;
  color: #0f4372 !important;
}

.card-cert-dash-last.card-title {
  font-size: 15px;
  color: #0f4372 !important;
  background: transparent !important;
}

.card-cert {
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.card-cert > .card-title {
  background-color: white;
}

.card-course-content {
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.card-course-content > .card-title {
  background-color: white;
}

.custom-table {
  border-collapse: collapse;
  border: none;
  background-color: white;
}

.custom-table td {
  padding: 10px;
  border: none;
}

/* MyComponent.css */
#mobile-tabs .nav-tabs {
  background-color: white !important;
  border-bottom: #0f4372;
}

#mobile-tabs {
  background-color: white;
}

#mobile-tabs .nav-link {
  background-color: white;
  border-color: white;
  border-bottom: 1px solid #0f4372 !important;
  border-right: none !important;
}

#mobile-tabs .nav-link.active {
  /* position: relative; */
  background-color: white;
  border-color: white;
  border-bottom: 2px solid #0f4372 !important;
  transform: scaleY(1);
  border-right: none !important;
}

.border-left {
  border-left: 1px solid #c4c4c4;
  /* height: 80% !important; */
}

.border-top {
  border-top: 1px solid #c4c4c4;
  height: 80% !important;
}

#info {
  background-color: #f0f7fd;
  border-radius: 15px;
  border-color: #f0f7fd;
}

#info > .row {
  background-color: #f0f7fd;
}

#info .col {
  background-color: #f0f7fd;
}

.tab-content {
  border-radius: 15px;
}

.tab-pane-content {
  /* background-color: #f0f7fd; */
  border-radius: 15px;
}

.increased-image-size {
  width: 50px;
  height: 50px;
}

.increased-image-size-mobile {
  width: 30px;
  height: 30px;
}

#about {
  background-color: #f0f7fd;
  border-radius: 15px;
  border-color: #f0f7fd;
}

.card-about {
  background-color: #f0f7fd;
  border: 1px solid;
  border-radius: 15px;
  border-color: #f0f7fd;
  color: #0f4372 !important;
}

.card-about>.card-title{
  background-color: #f0f7fd;
}

.card-about>p{
  background-color: #f0f7fd;
}

.card-course-content-list{
  background-color: #f0f7fd;
  border: 1px solid;
  border-radius: 15px;
  border-color: #f0f7fd;
  color: #0f4372 !important;
}

.card-course-content-list>.card-title{
  background-color: #f0f7fd;
}

.card-course-content-list a{
  background-color: #f0f7fd;
}

.card-course-content-list p{
  background-color: #f0f7fd;
}

.card-course-content-list *{
  color: #0f4372 !important;
}

.card-course-summary-sort{
  background-color: #f0f7fd;
  border: 1px solid;
  border-radius: 15px;
  border-color: #f0f7fd;
  color: #0f4372 !important;
}

.card-course-summary-sort *{
  background-color: #f0f7fd;
  color: #0f4372 !important;
}

.card-course-summary-sort>ul{
  background-color: #f0f7fd;
  color: #0f4372 !important;
}

.card-course-summary-sort>ul>li{
  background-color: #f0f7fd;
  color: #0f4372 !important;
}

.course-content-accordion{
  border: 1px solid;
  border-radius: 15px !important;
  /* border-color:red !important; */
  color: #0f4372 !important;
}

.course-content-accordion > *{
  border: 1px solid;
  border-radius: 15px !important;
  /* border-color:red !important; */
  color: #0f4372 !important;
}

.form-rounded {
  border-radius: 1rem;
}


.card-summary-team-completed {
  background-color: #c4fadb;
  color: #0f4372 ;
  border-radius: 15px !important;
}

.card-summary-team-completed  * {
  background-color: transparent;
  color: #0f4372  !important;
  border-radius: 15px !important;
}

.card-summary-team-ongoing {
  background-color: #ecc4fa;
  color: #0f4372 ;
  border-radius: 15px !important;
}

.card-summary-team-ongoing  * {
  background-color: transparent;
  color: #0f4372  !important;
  border-radius: 15px !important;
}

.card-summary-team-unstarted {
  background-color: #fac4c4;
  color: #0f4372 ;
  border-radius: 15px !important;
}

.card-summary-team-unstarted  * {
  background-color: transparent;
  color: #0f4372  !important;
  border-radius: 15px !important;
}

.card-today-schedule {
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.card-today-schedule  * {
  background-color: white;
}

.card-schedule-list {
  background-color: #fcfcfc;
  border: 1px solid;
  border-radius: 15px;
  border-color:#d9d9d9;
  color: #0f4372 !important;
}

.card-schedule-list * {
  background-color: #fcfcfc;
}

.card-about-class {
  background-color: #fcfcfc;
  border: 1px solid;
  border-radius: 15px;
  border-color: #d9d9d9;
  color: #0f4372 !important;
}

.card-about-class>.card-title{
  background-color: #fcfcfc;
}

.card-about-class>p{
  background-color: #fcfcfc;
}

.card-about-class *{
  background-color: #fcfcfc;
}

#info-class {
  background-color: #fcfcfc;
  border-radius: 15px;
  border-color: #d9d9d9;
}

#info-class * {
  background-color: #fcfcfc;
}


.td-40 {
  width: 40%;
}

.td-60 {
  width: 60%;
}

.card-class-content-list{
  background-color: #fcfcfc;
  border: 1px solid;
  border-radius: 15px;
  border-color: #d9d9d9;
  color: #0f4372 !important;
}

/* .card-class-content-list>.card-title{
  background-color: #fcfcfc;
}

.card-class-content-list a{
  background-color: #fcfcfc;
}

.card-class-content-list p{
  background-color: #fcfcfc;
} */

.card-class-content-list *{
  color: #0f4372 !important;
  background-color: #fcfcfc;
}

.team-summary-row *{
  color: #0f4372 !important;
}

.team-summary-row {
  white-space: nowrap;
}

.card-summary-team-completed,
.card-summary-team-ongoing,
.card-summary-team-unstarted {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  width: calc(33.33% - 10px);
  /* margin-right: 10px; */
  font-size: 14px;
}


.team-progress-label p{
  font-size: 16px !important;
  color: #0f4372 !important;
}

.circular_image_container {
  width: 117px;
  height: 117px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular_image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 991px) {
  .card-summary-team-completed,
  .card-summary-team-ongoing,
  .card-summary-team-unstarted {
    width: calc(50% - 10px);
    font-size: 16px;
  }

  .team-progress-label p{
    font-size: 16px !important;
  }
}

@media (max-width: 575px) {
  .card-summary-team-completed,
  .card-summary-team-ongoing,
  .card-summary-team-unstarted {
    width: 100%;
    margin-right: 0;
    /* margin-bottom: 10px; */
    font-size: 10px;
  }

  .team-progress-label p {
    font-size: 10px !important;
  }

  .circular_image_container{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  .circular_image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .team-name {
    font-size: 25px;
    background-color: #fffcdb;
    color: #0f4372 !important;
  }
  .team-nip {
    font-size: 20px;
    background-color: #fffcdb;
    color: #0f4372 !important;
  }
}

@media (max-width: 767px) {
  .team-name {
    font-size: 20px;
    background-color: #fffcdb;
    color: #0f4372 !important;
  }
  .team-nip {
    font-size: 15px;
    background-color: #fffcdb;
    color: #0f4372 !important;
  }
}

.team-name,
.team-nip {
  margin-bottom: 5px;
}

#accordion-trainer .accordion-body{
  background-color: #fcf2ed;
}

#accordion-trainer .accordion-button.collapsed{
  background-color: #fef4ef ;
}

#accordion-trainer .accordion-button{
  background-color: #fee3d5 ;
}


.modal-dialog{
  background-color: transparent;
}

.modal-body-rounded {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

/* sidabar */

#dropdown-basic{
  background-color: #f4f5f7 !important;
  color: #0f4372 !important;
  border-color: #86a0b89d !important;
}

#dropdown-basic::after{
  content: none;
}

.card-event-list {
  background-color: white;
  border: 1px solid;
  border-radius: 15px;
  border-color:#d9d9d9;
  color: #0f4372 !important;
}

.card-event-list * {
  background-color: white;
}

.image-event-container {
  position: relative;
  width: 100%; /* Set the width to match the parent's width */
  height: 150px; /* Set the height to 200px */
  overflow: hidden; /* Ensure that the image stays within the container */
}

.image-event-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px !important; /* Set the border-radius to 15px for rounded corners */
}

.btn-event-course{
  background-color: #0f4372 !important;
  width: 100px;
}

.btn-event-course *{
  background-color: #0f4372 !important;
}

#badge-category{
  background-color: #0f4372 !important;
  /* font-size: 12px !important; */
}

#badge-lh{
  background-color: #0f4372 !important;
  /* font-size: 12px !important; */
}

.btn-event-class{
  background-color: #7465c2 !important;
  border: #7465c2 !important;
  width: 100px;
}

.btn-event-class *{
  background-color: #7465c2 !important;
}

.btn-event-webinar{
  background-color: #489c94 !important;
  border: #489c94 !important;
  width: 100px;
}

.btn-event-webinar *{
  background-color: #489c94 !important;
}

.current-date {
  background-color: #eb5757;
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regular-date {
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#dropdown-sort{
  border-radius: 15px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: white !important;
  color: darkgray !important;
  border-color: darkgray !important;
  height: 47px;
  width: 100%;
}

#dropdown-sort-2{
  background-color: white !important;
  color: darkgray !important;
  border-color: darkgray !important;
}

#dropdown-sort-small{
  background-color: white !important;
  color: darkgray !important;
  border-color: darkgray !important;
}

.search-sort-container {
  display: flex;
  align-items: center;
}

.search-input-container {
  position: relative;
  flex: 1;
}

.search-input {
  padding: 10px 30px 10px 10px;
  border-radius: 15px !important;
  border: 1px solid #ccc;
  width: 100%;
  background-color: #fff;
  font-size: 15px !important;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  background-color: #fff;
}

.sort-dropdown-container {
  height: 100% !important;
}

.pill-button {
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.evaluate-program-list{
  background-color: #f5ffe1 !important;
  border: 1px solid #42d934;
  border-radius: 13px !important;
}

.evaluate-program-list *{
  /* background-color: #f5ffe1 !important; */
  /* color: #219653 !important */
}

.badge-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

#btn-hide-list{
  background-color: #0f4372 !important;
  border: none; 
  color: white;
}

#btn-show-list{
  background-color: #0f4372 !important;
  border: none; 
  color: white;
  width: 70px;
}

#accordion-module {
  /* border: 1px solid red; */
}

#accordion-module *{
  background-color: white;
}

.accordion-collapse.collapse.show >.accordion-body {
  /* font-size: 100px; */
}

/* accordion-collapse collapse show */

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #eb5757;
  margin-left: 10px;
  display: inline-block;
  margin-top: 5px;
}

.square-image {
  /* width: 100px;
  height: 100px;
  overflow: hidden; */
}
.image-participant {
  width: 70px;
  height: 70px;
  object-fit: cover;
  position: relative;
}

.image-team-summary {
  width: 117px;
  height: 117px;
  object-fit: cover;
  position: relative;
}

#class-summary-info *{
  background-color: white;
}

#card-program-detail-summary{
  border-radius: 15px;
  background-color: #daedfd !important;
}

#card-program-detail-summary *{
  color: #0f4372 !important;
  background-color: transparent;
}

#card-program-detail-summary-info{
  border-radius: 15px;
  background-color: #b1dafd !important;
}

.dropdown-container .dropdown-menu {
  width: 100%;
}

#card-cert-summary{
  border-radius: 15px;
  border: 1px solid #f2994a !important;
  background-color: #fffcdb !important;
}

#card-cert-summary *{
  color: #0f4372 !important;
  background-color: transparent;
}

.card-cert-2{
  border-radius: 15px;
  border: 1px solid #f2994a !important;
  background-color: #fffcdb !important;
}

.card-cert-info{
  border-radius: 15px;
  background-color: #fff699 !important;
}

#card-cert-info *{
  color: #0f4372 !important;
  background-color: #fff699 !important;
}

#card-cert-info .col{
  color: #0f4372 !important;
  background-color: #fff699;
}

.accordion-button::after{
  display: none !important;
}

.active-row-module > div,
.active-row-module > div * {
  background-color: #0f4372 !important;
  color: white;
}

.badge-classroom{
  background-color: #f2994a !important;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.badge-quiz{
  background-color: #eb5757 !important;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.badge-news{
  background-color: #2f80ed !important;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

#msg-container{
  border-radius: 15px  !important;
  background-color: #f4f5f7  !important;
  border: 1px solid #aaa5a5;
  color: gray;
}

.msg-unread * {
  font-weight: bold !important;
}

.msg-read * {
  font-weight: normal !important;
}


#about-container  {
  background-color: #f0f7fd !important;
}

#dropdown-message-content{
  background-color: transparent !important;
  border: none !important;
}

#swal2-title{
  background-color: transparent !important;
}

#swal2-html-container {
  background-color: transparent !important;
}

.swal2-actions {
  background-color: transparent !important;
}

.Toastify__toast-container{
  background-color: transparent !important;
}

.Toastify__toast-body{
  background-color: inherit !important;
}

.Toastify__toast-body > div {
  background-color: inherit !important;
}

.Toastify__toast-icon > svg {
  background-color: transparent !important;
}

.Toastify__close-button > svg {
  background-color: transparent !important;
}

.swal2-icon-content {
  background-color: transparent !important;
}

.swal2-icon-show {
  background-color: inherit !important;
}

#badge-inbox{
  background-color: #0f4372 !important;
  padding: 2px 7px 2px 7px !important;
  color: white ;
  border-radius: 50%;
}

#badge-inbox-active{
  background-color: white !important ;
  padding: 2px 7px 2px 7px !important;
  color: #0f4372 ;
  border-radius: 50%;
}

.card-leaderboar-profile-summary {
  background-color: white;
  /* border: 1px solid; */
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.card-leaderboar-profile-summary  * {
  background-color: white;
}

.card-leaderboard-profile-info {
  display: flex;
  align-items: center;
  justify-content: start;
  text-decoration: none; /* Remove underline */
}

.card-leaderboard-profile-info * {
  background-color: white !important;
}

.link-my-rank{
  color: #0f4372 !important;
}

.card-leaderboard{
  border-radius: 15px;
  background-color: #fffcdb;
  border: 1px solid #f8cca4;
}

.card-leaderboard *{
  background-color: #fffcdb;
  color: #0f4372 !important;
}


#badge-point-leaderboard{
  font-weight: normal;
  background-color: #fbc926 !important;
  padding: 4px;
  border-radius: 5px;
}

#sidebar-menu{
  max-width: 180px !important;
}

.header-participant-evaluate-list{
  border-radius: 15px;
  background-color: #f0ffcf;
  color: #219653;
  font-weight: bold;
}

.header-participant-evaluate-list *{
  background-color: #f0ffcf;
}

.list-rank{
  border-radius: 15px;
  background-color: #fffcdb;
  border: 1px solid #f8cca4;
}

.list-rank *{
  background-color: #fffcdb;
  color: #0f4372 !important;
  font-size: 16px;
}



.row-list-rank{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.25rem !important;
  padding-bottom:  0.25rem !important;
}

.evaluate-participant {
  width: 60px;
  height: 60px;
  object-fit: cover;
  position: relative;
}

.evaluate-participant-list{
  color: #219653;
  border-bottom: 0.1rem solid #219653;
}

.evaluate-participant-list h6{
  color: #219653 !important;
}

.evaluate-participant-detail{
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: relative;
}

.question-list{
  border: 1px solid #0f4372;
  border-radius: 10px !important;
}

/* .star {
  color: gray;
  cursor: pointer;
}

.star.filled {
  color: yellow;
}

.star:hover,
.star:hover ~ .star {
  color: yellow;
} */



.starrating > input {display: none;}  /* Remove radio buttons */

.starrating > label:before { 
  content: "\f005"; /* Star */
  margin: 2px;
  font-size: 2em;
  font-family: FontAwesome;
  /* display: inline-block;  */
}

.starrating > label
{
  color: #bdbdbd; /* Start color when not clicked */
}

.starrating > input:checked ~ label
{ color: #ffca08 ; } /* Set yellow color when star checked */

.starrating > input:hover ~ label
{ color: #ffca08 ;  } /* Set yellow color when star hover */

.submit-evaluation{
  background-color: #219653 !important;
  border: none !important;
  width: 200px !important;
  border-radius: 15px !important;
}

.list-notif{
  text-decoration: none;
}

.clickable{
  cursor: pointer;
}

.card-white-rounded {
  background-color: white;
  border-radius: 15px;
  border-color: white;
  color: #0f4372 !important;
}

.my-profile-photo{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.my-profile-photo-container *{
  background-color: transparent;
  color: #0f4372 !important;
}

.btn-change-picture{
  color: #0f4372 !important;
  border-color: #0f4372 !important;
}

.my-profile-overview-item{
  border: 1px solid #fff699 !important;
  background-color: #fff699 !important;
  border-radius: 15px;
  color: #0f4372 !important;
}

.my-profile-overview-item *{
  background-color: #fff699 !important; 
}

.my-profile-overview-container *{
  background-color: white;
}

.profile-data-form *{
  background-color: white;
}

.blue-line {
  border: 1px solid #0f4372;
}

#btn-update-profile{
  background-color: #0f4372;
  color: white;
}

.update-password-data-form *{
  background-color: white;
}


.dropdown-menu.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#about-container *{
  background-color: transparent !important;
}

.reactEasyCrop_CropArea{
  background: transparent;
}

.toast-text-color {
  color: #0f4372;
}
.toast-progress > div {
  background-color: #0f4372 !important;
}

.gradient-skeleton {
  background: linear-gradient(to right, #eeeeee 0%, #dddddd 50%, #eeeeee 100%);
  background-size: 200% 100%;
  animation: shimmer-gradient 1.5s ease-in-out infinite;
}

@keyframes shimmer-gradient {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}


span[aria-live="polite"][aria-busy="true"] {
  background-color: transparent;
}

@media (max-width: 1080px) {
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
  }
}